import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { Container, Layout } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import ProductsFinder from 'components/ProductsFinder';
import HeroBanner from 'components/HeroBanner';
import FeaturedProducts from 'components/FeaturedProducts';
import FeaturedArticles from 'components/FeaturedArticles';
import PromoElement from 'components/PromoElement';
import HerbalBanner from 'components/HerbalBanner/HerbalBanner';
import MomentsBlock from 'components/MomentsBlock/MomentsBlock';
import HerbalProducts from 'components/HerbalProducts/HerbalProducts';
import HerbalBenefits from 'components/HerbalBenefits/HerbalBenefits';
import FeaturedItems from 'components/FeaturedItems';
import { isNotEmptyArray } from 'utils/array';
import { schemaService } from 'utils/schemaService';

import { IPropsHomePage } from './model';

import './HomePage.scss';
import './ShadowHomePage.scss';

const activeColor = '#FDBD21';

const HomePage: FC<IPropsHomePage> = ({
  data: {
    pageData,
    relatedProducts,
    featuredProductsByTag,
    featuredArticlesByLink,
    featuredArticlesByTag,
    featuredArticlesSymptomsByLink,
    featuredArticlesSymptomsByTag,
  },
  pageContext: { lang, link },
}) => {
  const {
    defaultCompositions,
    seo,
    startQuiz,
    heroBanner,
    banner,
    featuredArticlesList,
    moments,
    products,
    benefits,
    featuredItems,
  } = pageData;
  const isStartQuizExist = Boolean(startQuiz);

  return (
    <Layout
      className="home-page"
      seo={seo}
      defaultCompositions={defaultCompositions}
      additionalStructuredSchemas={[schemaService.schemaTypes.Organization]}
      dynamicStructuredSchemaValues={{ pageUrl: link }}
    >
      <div />
      {heroBanner?.length ? (
        <HeroBanner heroBanner={heroBanner} isHomePage isLazyLoading={false} />
      ) : null}
      {banner?.length ? <HerbalBanner data={banner[0]} /> : null}
      <div className="home-page__container">
        {startQuiz ? (
          <ProductsFinder
            startQuiz={startQuiz}
            productQuizData={defaultCompositions.productQuiz}
            ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
            ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
            lang={lang}
          />
        ) : null}

        {pageData?.relatedProductImagePluses?.[0]?.properties?.image ? (
          <Container>
            <GatsbyImage
              image={pageData.relatedProductImagePluses[0].properties.image}
              alt={pageData.relatedProductImagePluses[0].properties?.altLabel}
              className={classNames('home-page__image-pluses', { 'move-top': isStartQuizExist })}
              fadeIn={false}
            />
          </Container>
        ) : null}

        {pageData?.relatedProducts?.length ? (
          <FeaturedProducts
            featuredProducts={pageData.relatedProducts}
            featuredProductsByLink={relatedProducts.nodes}
            featuredProductsByTag={featuredProductsByTag.nodes}
            productsOrder={pageData?.relatedProducts?.[0]?.properties?.selectedProductsIds || []}
            dir={defaultCompositions.siteSettings.dir[0]}
            ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
            ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
            activeColor={activeColor}
            addClassName={classNames({ 'move-top': isStartQuizExist })}
          />
        ) : null}
        {featuredItems?.[0]?.properties ? (
          <FeaturedItems
            image={featuredItems[0].properties.image}
            imageAlt={featuredItems[0].properties.imageAlt}
            items={featuredItems[0].properties.items}
          />
        ) : null}
        {isNotEmptyArray(featuredArticlesList) ? (
          <FeaturedArticles
            featuredArticlesList={featuredArticlesList}
            featuredArticlesByLink={[
              ...featuredArticlesByLink.nodes,
              ...featuredArticlesSymptomsByLink.nodes,
            ]}
            featuredArticlesByTag={[
              ...featuredArticlesByTag.nodes,
              ...featuredArticlesSymptomsByTag.nodes,
            ]}
            dir={defaultCompositions.siteSettings.dir[0]}
            ariaLabelPrev={defaultCompositions.siteSettings.ariaLabelPrev}
            ariaLabelNext={defaultCompositions.siteSettings.ariaLabelNext}
          />
        ) : null}
        {pageData?.promoElement?.length ? (
          <div className="promo-element__wrapper">
            <PromoElement promoElement={pageData.promoElement} />
          </div>
        ) : null}
      </div>
      {pageData?.moments?.length ? (
        <Container>
          <MomentsBlock data={moments[0]} />
        </Container>
      ) : null}
      {pageData?.products?.length ? (
        <HerbalProducts
          data={products[0]}
          prevAriaLabel={defaultCompositions.siteSettings.ariaLabelPrev}
          nextAriaLabel={defaultCompositions.siteSettings.ariaLabelNext}
        />
      ) : null}
      {pageData?.benefits?.length ? (
        <HerbalBenefits
          data={benefits[0]}
          prevAriaLabel={defaultCompositions.siteSettings.ariaLabelPrev}
          nextAriaLabel={defaultCompositions.siteSettings.ariaLabelNext}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query (
    $link: String!
    $lang: String!
    $selectedProductsLinks: [String]
    $selectedProductTag: Int
    $selectedArticlesLinks: [String]
    $selectedArticlesTag: Int
  ) {
    pageData: homepage(link: { eq: $link }, lang: { eq: $lang }) {
      relatedProducts {
        ...FragmentFeaturedProducts
      }
      relatedProductImagePluses {
        properties {
          altLabel
          image {
            ...FragmentGatsbyImage
          }
        }
      }
      promoElement {
        ...FragmentPromoElement
      }
      banner {
        ...FragmentHerbalBanner
      }
      heroBanner {
        properties {
          subTitle
          title
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          mobileImage: image {
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 770, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageS {
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          mobileImageS: imageS {
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 200, quality: 60) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageSAltLabel
          imagePluses {
            ...FragmentGatsbyImage
          }
          mobileImagePluses: imagePluses {
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 71, quality: 30) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imagePlusesAltLabel
          buttons {
            ...FragmentButton
          }
          heroBannerImageAltText
        }
      }
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      startQuiz {
        ...FragmentStartQuizSection
      }
      featuredArticlesList {
        ...FragmentFeaturedArticles
      }
      moments {
        ...FragmentMomentsBlock
      }
      products {
        ...FragmentHerbalProducts
      }
      benefits {
        ...FragmentHerbalBenefits
      }
      featuredItems {
        ...FragmentFeaturedItems
      }
    }
    relatedProducts: allProduct(
      filter: { link: { in: $selectedProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    featuredProductsByTag: allProduct(
      filter: { tags: { elemMatch: { id: { eq: $selectedProductTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCardRelated
      }
    }
    featuredArticlesByLink: allArticle(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
    featuredArticlesByTag: allArticle(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCardRelated
      }
    }
    featuredArticlesSymptomsByLink: allArticleSymptoms(
      filter: { link: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleSymptomsCardRelated
      }
    }
    featuredArticlesSymptomsByTag: allArticleSymptoms(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleSymptomsCardRelated
      }
    }
  }
`;

export default HomePage;
